import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`A `}<inlineCode parentName="p">{`Switch`}</inlineCode>{` is used to immediately enable or disable the state of an action.
These two actions should be simple to understand and represent an opposite
meaning (E.g. 'On' & 'Off'). They are the preferred way to adjust settings on
mobile.`}</p>
    <h2 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h2>
    <p>{`The switch's states are saved immediately on click. Just like a light switch:
flipping the switch turns the light directly on or off, which differs from a
radio group in that a radio's action is initiated from a form submit button.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <h3 {...{
      "id": "label-text",
      "style": {
        "position": "relative"
      }
    }}>{`Label text`}</h3>
    <p>{`All switches should always have an associated label that clearly describes the
on state of the control. Avoid writing labels that includes the text "on" and
"off".`}</p>
    <p>{`An inline helper message can be added to aid in use.`}</p>
    <p>{`Both the label and the helper text styles (font-family, font size, font-weight,
color) are customizable for specific use cases. Check
`}<a parentName="p" {...{
        "href": "/foundations/fonts"
      }}>{`Chameleon's Typographic System`}</a>{` for possibilities and
guidelines.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=1153%3A0" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "cross-platform",
      "style": {
        "position": "relative"
      }
    }}>{`Cross platform`}</h3>
    <p>{`Design conventions differ from platform to platform. These differences in
convention can affect the user's ability to understand the UI or complete
certain tasks. In such cases, it’s recommended to adapt to platform-specific
conventions.`}</p>
    <p>{`Native platform switches should be used as they have matching functionality.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=1136%3A942" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`If a user has to make a `}<strong parentName="li">{`single`}</strong>{` choice from a list of options, use the
`}<a parentName="li" {...{
          "href": "/components/radio/code"
        }}>{`Radio`}</a>{` or `}<a parentName="li" {...{
          "href": "/components/choice/code"
        }}>{`Choice radio`}</a>{`
components.`}</li>
      <li parentName="ul">{`To create a simple list of option where the user can make `}<strong parentName="li">{`multiple`}</strong>{`
selections, use the `}<a parentName="li" {...{
          "href": "/components/checkbox/code"
        }}>{`Checkbox`}</a>{` or
`}<a parentName="li" {...{
          "href": "/components/choice/code"
        }}>{`Checkbox Choice`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      